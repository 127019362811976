import Layout from "../layout"
import Lenis from 'lenis'
import Menu from "../components/menu"
import Page from "../components/page"
import Opening from "../components/opening"
import Rails from "@rails/ujs"
import Analytics from "../components/analytics"
import mitt from 'mitt'
window.LOAD_EVENT = mitt()
class App extends Layout {
    constructor() {
        super()
        Rails.start()
        this.status = "loading"
        this.page = new Page()
        this.menu = new Menu()
        //
        const title = document.querySelector("#opening-title")
        if (title) this.opening = new Opening(title)
        if (this.isProduction()) {
            this.analytics = new Analytics('G-7XVPDWM21T')
        }
        // this.setVideos()
        this.addEvents()
    }
    
    start() {
        this.setLenis()
        window.RAF.on("animate", (delta) => this.render(delta))
    }
    
    addEvents() {
        window.onload = (e) => this.loaded(e)
        window.addEventListener("pageshow",  (e) => this.pageShow(e))
        window.onpopstate = (e) => this.reload(e)
        //
        window.SIZE.on('resize', () => this.resize())
    }

    resize() {
        if (this.opening) this.opening.onResize()
    }

    setVideos() {
        document.querySelectorAll("video").forEach(video => this.addVideoObserver(video))
    }

    addVideoObserver(_video) {
        let options = {
            threshold: [0, .5, 1],
            rootMargin: "0px 0px"
        }
        this.observer = new IntersectionObserver(entries => {
            let entry = entries[0]
            if (entry.isIntersecting) return _video.play()
            else _video.pause()
        }, options)
        this.observer.observe(_video)
    }

    setLenis() {
        this.lerp = .9
        this.lenis = new Lenis({
            duration: .1,
            lerp: this.lerp,
            orientation: 'vertical',
            gestureOrientation: 'vertical',
            smoothWheel: true,
            smoothTouch: true
        })
    }

    render() {
        const time = new Date().getTime() * .1
        this.lenis.raf(time)
        if (this.opening) this.opening.render()
    }

    loaded() {
        if (this.status == "loaded") return false
        this.status = "loaded"
        window.LOAD_EVENT.emit('loaded')
        //
        this.start()
        this.preloader.hide( () => {
            if (this.opening) this.opening.show()
        })
        if (this.analytics) this.analytics.load()
    }
 
    isProduction() {
        return window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1'
    }
}

const _app = new App()