import lightGallery from 'lightgallery'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'

export default class Gallery {
    constructor() {
        this.gallery = null
        this.container = document.querySelector(".gallery")
    }

    setup() {
        if (!this.container) return false
        this.container.addEventListener('lgAfterSlide', (event) => {
            const { index, prevIndex } = event.detail;
            const slide = document.querySelectorAll('.lg-item')[index];
            const video = slide.querySelector('video');
            if (video) {
                video.play();
            }
        });
        return lightGallery(this.container, {
            plugins: [lgVideo, lgThumbnail, lgZoom],
            thumbnail: this.container.children.length > 1,
            licenseKey: 'E03B35F9-3212-4C64-8227-E87F9DB532AC',
            zoom: true,
            videojs: true,
            videojsOptions: {
                controls: true,
                preload: 'auto',
            }
        })
    }

    reload() {
        this.container = document.querySelector(".gallery")
        this.gallery = this.setup()
    }

    destroy() {
        if (this.gallery) this.gallery.destroy()
        this.gallery = null
        this.container = null
    }

}